input, textarea, select, option {
	color: black;
	/*border: 1px solid var(--blue1);*/
	
	background-color: white;
	border: 1px solid hsl(0, 0%, 90%);

	padding: 5px;
	width: 200px;
}

tr:nth-child(even) {background: #f5f5f5}

::placeholder{
	color: #d0d0d0;
	font-style: italic;
	font-weight: lighter;
}

body {
	background-color: #e3e6e4;
}

* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	font-family: "Segoe UI Semibold", serif;

	/*color: black;*/
	color: var(--gray2);
}