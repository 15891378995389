@import "defaults.css";

:root {
    /* OLD STUFF */
    --gray0: #f2f2f2;
    --gray1: #808080;
    --gray2: #777777;

    --dark0: #333333;
    --dark1: #262626;
    --dark2: #1a1a1a;

    --xr-dark: #02a59b;
    --xr-light: #5ad2d2;

    --gray0-alt: #4d4d4d;
    --gray1-alt: #3f3f3f;
    --gray2-alt: #333333;
    
    --green: #02a42d;
    
    /* NEW STUFF */
    --blue0: #0E6CCC;
    --blue1: #0B4B8D;
    --blue2: #11274b;
    --blue3: #1d262d;

    --alt-white: #f4f4f4;
    --orange0: #E98C1B;
    --orange1: #E9511B;
    
    /* PANELS */
    --panel-background: white;
    --big-panel-background: hsl(0,0%,98%);
    
    /* DASHBOARD CARDS */
    --card-background: white;
    --card-content: var(--blue0);
    --card-label: var(--gray1);
    
    /* GENERIC TABLE */
    --table-header: var(--blue0);
    --table-content: black;
    --table-background: var(--alt-white);
}

img.tint-xr{
    filter: invert(47%) sepia(18%) saturate(6013%) hue-rotate(145deg) brightness(92%) contrast(98%);
}

img.tint-xr--dark {
    filter: invert(91%) sepia(11%) saturate(2481%) hue-rotate(133deg) brightness(91%) contrast(80%);
}

.max {
    width: 100%;
    height: 100%;
}

.clamp-max{
    max-width: 100%;
    max-height: 100%;
}

.c-gray0    {      color: var(--gray0);         }
.c-gray1    {      color: var(--gray1);         }
.c-gray2    {      color: var(--gray2);         }
.c-dark0    {      color: var(--dark0);         }
.c-dark1    {      color: var(--dark1);         }
.c-dark2    {      color: var(--dark2);         }
.c-xr       {      color: var(--xr-light);      }
.c-xr--dark {      color: var(--xr-dark);       }

.c-main {color: var(--blue0);}
.c-main1 {color: var(--blue1);}


.t-right  { text-align: right;  }
.t-center { text-align: center; }

/*.card .f-big     {   font-size: 2em;     }*/
/*.card .f-normal  {   font-size: 1.25em;  }*/
/*.card .f-small   {   font-size: 0.75em;  }*/

svg.fill-red    *:not([fill="none"]) { fill: red;              }
svg.fill-green  *:not([fill="none"]) { fill: var(--green);     }
svg.fill-xr     *:not([fill="none"]) { fill: var(--xr-light);  }

svg.fill-gray0  *:not([fill="none"]) { fill: var(--gray0);     }
svg.fill-gray1  *:not([fill="none"]) { fill: var(--gray1);     }
svg.fill-gray2  *:not([fill="none"]) { fill: var(--gray2);     }
svg.fill-dark0  *:not([fill="none"]) { fill: var(--dark0);     }
svg.fill-dark1  *:not([fill="none"]) { fill: var(--dark1);     }
svg.fill-dark2  *:not([fill="none"]) { fill: var(--dark2);     }
svg.fill-black  *:not([fill="none"]) { fill: black;            }

svg.fill-blue0  *:not([fill="none"]) { fill: var(--blue0);     }
svg.fill-blue1  *:not([fill="none"]) { fill: var(--blue1);     }

.pointer { cursor: pointer; }